<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-row>

                                    <a-col :span="20">
                                        <a-form-item>
                                            <!--<label class="yj-partner-check_lab">红包明细</label>-->

                                            <span style=" margin-left:30px">
                                                <label>已发金额：</label>
                                                <a-input-number style="margin:0 10px" v-model="Expenditure" :disabled="disabled" />
                                            </span>
                                            <span style=" margin-left:10px">
                                                <label>剩余金额：</label>
                                                <a-input-number style="margin:0 10px" v-model="sum" :disabled="disabled" />
                                            </span>
                                            <span style=" margin-left:10px">
                                                <label>累计领取：</label>
                                                <a-input-number style="margin:0 10px" v-model="Cumulative" :disabled="disabled" />个
                                            </span>
                                        </a-form-item>

                                    </a-col>
                                </a-row>

                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="imgs" slot-scope="text">
                                <img class="imgPreview" :src="text" alt="头像" />
                            </span>

                        </a-table>


                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "头像",
                        dataIndex: "UHeadImg",
                        key: "HeadImg",
                        align: "center",
                        width: 200,

                        scopedSlots: { customRender: "imgs" },
                    },
                    {
                        title: "昵称/姓名",
                        dataIndex: "UserName",
                        width: 300,
                        ellipsis: true
                    },
                    {
                        title: "领取金额",
                        dataIndex: "LuckMoney",
                        width: 300,
                        ellipsis: true
                    },

                    {
                        title: "创建时间",
                         width: 300,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    //{
                    //    title: "领取时间",
                    //    dataIndex: "InputTime",
                    //    width: 200,
                    //    ellipsis: true
                    //},
                    {
                        title: "通过素材",
                        dataIndex: "MaterialName",
                        width: 200,
                        ellipsis: true
                    },
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1",
                Expenditure: "",  //支出金额
                sum: "",  //总金额
                ID: 0,
                disabled: true,
                Cumulative: "",
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetDetailsShow",
                    data: {
                        PageIndex: self.pagination.current,
                        ID: self.ID,
                    },
                    OnSuccess: function (data) {
                        data.data.Data.Data.forEach(element => {
                            element.LuckMoney = parseFloat(element.LuckMoney).toFixed(2);
                        });
                        self.Expenditure = data.data.Expenditure;
                        self.sum = data.data.sum;
                        self.Cumulative = data.data.people;
                        self.tableData = data.data.Data.Data;
                        self.pagination.total = data.data.Data.Total;
                        console.log(self.pagination.total);
                        self.pagination.defaultPageSize = data.data.Data.RowCount;
                        self.pagination.current = data.data.Data.PageIndex;
                    }
                };
                http.Post(op);
            },

        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.ID = self.$route.query.ID;
            self.getTableData();
        },

        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>
<style>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }


</style>